.vacanciesValues_container {
  background: #f8f8f8;
  padding: 50px 20px;
  .vacanciesValues_block {
    max-width: 1200px;
    margin: 0 auto;
    .vacanciesValues_title {
      h1 {
        color: #202020;
        font-family: "Rubik";
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 114%;
      }
      p {
        color: rgba(32, 32, 32, 0.87);
        font-family: "Rubik";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 20px;
      }
    }
    .vacanciesValues_item {
      margin-top: 30px;
      .navigation_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
      }

      .navigation_buttons button {
        background-color: #ea3e2a;
        border-radius: 6px;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        font-family: "Rubik";
        margin: 0 10px;
        color: white;
        &:hover {
          background-color: #ea3e2a;
        }
        cursor: pointer;
        
      }

      .navigation_buttons button:hover {
        background-color: #d94444;
      }
    }
    @media screen and (max-width: 768px) {
      .vacanciesValues_title {
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}
