.homeServices_container{
    background: #F2F3F7;
    padding: 50px 20px;
    .homeServices_block{ 
        max-width: 1200px;
        margin: 0 auto;
        h1{
            color: #202020;
            font-family: 'Rubik';
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; /* 57.143% */            
        }
        .homeServices_item{
            margin-top: 30px; 
            
        }
        @media screen and(max-width: 768px) {
            h1{
                font-size: 30px; 
                font-weight: 500;
            }
        }
    }

}