.tarifs_container{
    padding: 50px 20px;
    .tarifs_block{
        max-width: 1200px;
        margin: 0 auto;
        h1{
            color: #202020; 
            font-family: 'Rubik';
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;            
        }
        .tarifs_title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            max-width: 800px;
            margin-top: 40px;
            .tarifsTitle_text{

                p{
                    color: #202020;
                    text-align: center;
                    font-family: 'Rubik';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    white-space: nowrap;
                    padding-bottom: 15px;
                    cursor: pointer;             
                }
            }
            }
                @media screen and(max-width: 850px) {
                    .tarifs_title{
    
                        overflow-x: scroll;
                    }
                  h1{
                    font-size: 30px;
                    font-weight: 500;
                  }
                }
    }
    
}