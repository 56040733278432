.adminMenuLinkCardText_block {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 10px;
  text-decoration: none;
  p {
      color: #7d7d7d;
      font-family: "Rubik";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:hover {
      .adminMenu_icon {
        color: rgba(234, 62, 42, 1);
      }
    }
  .adminMenu_icon {
    color: #7d7d7d;
    font-size: 20px;
  }
}
.active {
  p {
    color: #202020;

    font-family: "Rubik";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .adminMenu_icon {
    color: rgba(234, 62, 42, 1);
  }
}
