.adminHomeTrafficsCard_block {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);
  padding: 20px;
  h1 {
    color: #000;
    font-family: 'Rubik';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .adminHomeTrafficsCard_item {
    margin-top: 30px;
  }
  h6 {
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.58);
    font-family: "Rubik";
    font-size: 11.008px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}
