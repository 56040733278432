.adminHomePackagesCard_block{
    border-radius: 8px;
    background: rgba(217, 217, 217, 0.03); 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14); 
    padding: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px; 
    height: 100%;
    min-width: 205px;
    .adminHomePackagesCard_item{
        h3{
            color: rgba(0, 0, 0, 0.87);
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;  
        }
        p{
            color: rgba(0, 0, 0, 0.58);
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; 
            margin-top: 30px; 
        }
    } 
    .adminServicesModuleModal_container{
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        padding: 20px;
        .adminServicesModuleModal_block{
            max-width: 1200px;
            padding: 40px;
            background-color: white;
            border-radius: 16px;
            @media screen and (max-width: 1000px) {
                height: 100%;
                overflow: scroll;
            }
            .adminServicesModuleModal_item{
              .adminServicesModuleModal_text{
                margin-top: 20px;
                h4{
                    font-size: 16px;
                    font-family: 'Rubik';
                }
                p{
                    margin-top: 10px;
                    font-family: 'Rubik';
                }
              }
              .adminServicesModuleModal_btn{
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
        }
    }
    @media screen and (max-width: 1150px) {
        width: 100%;
        height: 225px;
    }
}