.adminLogin_container{
    background-color: #F2F3F7;
    padding: 20px;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .adminLogin_block{
        max-width: 1000px;
        height: 80%; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .adminLoginSecurity_text{
            p{
                font-family: 'Rubik';
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                text-align: center; 
                color: #666B76;

                span{
                    color: #7598F7;
                }
            }
        }
    }
}