.adminHomeBalanceCard_block{
    border-radius: 24px;
    background: #FFF; 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14); 
    padding: 20px;
    height: 100%;
    .adminServicesModuleModal_container{
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        padding: 20px;
        .adminServicesModuleModal_block{
            max-width: 1200px;
            padding: 20px;
            background-color: white;
            border-radius: 16px;
            @media screen and (max-width: 1000px) {
                height: 100%;
                overflow: scroll;
            }
            .adminServicesModuleModal_item{
              .adminServicesModuleModal_text{
                margin-top: 20px;
                h4{
                    font-size: 16px;
                    font-family: 'Rubik';
                }
                p{
                    margin-top: 10px;
                    font-family: 'Rubik';
                }
              }
              .adminServicesModuleModal_btn{
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
        }
    }
    .adminHomeBalanceCardTop_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .adminHomeBalanceCardTop_item{
            h2{
                color: #000;
                font-family: 'Rubik';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            h3{
                color: #EA3E2A;
                font-family: 'Rubik';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 10px;
            }
        }

    }  
    .adminHomeBalanceCardTarif_block{
        margin-top: 20px;
        h4{
            color: rgba(0, 0, 0, 0.87);
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;  
        }
        p{
            color: rgba(0, 0, 0, 0.58);
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;  
        }
    } 
    h6{
        margin: 30px 0px 0 0;
        color: rgba(0, 0, 0, 0.58);
        font-family: 'Rubik';
        font-size: 11.008px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;       
    }
}