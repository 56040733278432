.adminHomeTrafficsCardBattty_block{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.22);
    .adminHomeTrafficsCardBattty_item{
        background-color: #E2E5EB;
        padding: 10px;
        border-radius: 8px;
        .adminHomeTrafficsCardBatttyText_block{
            background-color: white;
            border-radius: 8px;
            padding: 10px;
            .adminHomeTrafficsCardBatttyText_item{
                width: 140px;
                height: 52px;
                position: relative;
                background-color: #E2E5EB;
                border-radius: 8px;
                .adminHomeTrafficsCardBatttyText_line{
                    height: 100%;
                    background-color: #F80101;
                    border-radius: 8px;
                }
                .adminHomeTrafficsCardBatttyText{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    h2{
                        color: #FFF;
                        font-family: 'Rubik';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;                     
                    }
                    h3{
                        color: #000;
                        font-family: 'Rubik';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;                      
                    }
                }
            }
        }
    }
    .adminHomeTrafficsCardBattty_radius{
        width: 15px;
        height: 30px;
        background-color: #E2E5EB;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        
    }
}