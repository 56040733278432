.routerWarrantyCard_block{
    border-radius: 12px;
    background: var(--msk-rt-ru-alabaster, #F8F8F8);
    padding: 20px;
    min-height: 255px;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    h2{
        color: var(--msk-rt-ru-ebony, #101828);
        font-family: 'Rubik';
        font-size: 25.813px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; 
    }   
    p{
        color: var(--msk-rt-ru-ebony-70, rgba(16, 24, 40, 0.70)); 
        font-family: 'Rubik';
        font-size: 17.297px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-top: 15px; 
    } 
    ul{
        margin-top: 10px;
        margin-left: 20px;
        li{
            color: var(--msk-rt-ru-ebony-70, rgba(16, 24, 40, 0.70)); 
            font-family: 'Rubik';
            font-size: 17.297px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;             
        }
    }
    @media screen and (max-width: 950px) {
        width: 100%;
    }
    @media screen and (max-width: 768px) { 
        h2{ 
            font-size: 20px; 
        }   
        p{
            font-size: 16px;
            margin-top: 15px; 
        } 
        ul{ 
            li{ 
                font-size: 16px;              
            }
        } 
    }
}