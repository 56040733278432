.whatWhois_block {
  margin-top: 80px;
  h1 {
    color: #202020;
    text-align: center;
    font-family: "Rubik";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 95.238% */
  }
  .whatWhois_item {
    margin-top: 50px;
    border-radius: 16px;
    background: #FFF; 
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);    
    padding: 50px;
    h4{
        color: #202020;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
    }
    .whatWhoisText_block{
        margin-top: 20px;
        p{
            color: #202020;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;      
        }
        ul{
            margin-left: 30px;
            margin-top: 20px;    
            li{
                line-height: 140%;
                color: #202020;
                font-family: 'Rubik';
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 150%; 
                margin-top: 10px;
            }        
        }
    }
  }
  @media screen and (max-width: 768px) {
    h1{
        font-size: 30px;
    }
    .whatWhois_item{
        padding: 50px 30px;
        .whatWhoisText_block{ 
            ul{
                margin-left: 20px;    
       
            }
        }
    }

  }
}
