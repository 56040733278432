.tarifCard_block{
  width: 350px;
  border-radius: 24px;
  border-radius: 24px;
  min-height: 600px;
  background: #FFF;
  box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14); 
  // min-height: 603px;
  .tarifCardTop_block{
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(../../../../../Assets/Home/img/image16.png);
      height: 100px;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 20px; 
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      overflow: hidden;
      .tarifCardTop_item{
        background-color: #EA3E2A;
        padding: 5px 10px;
        transform: rotate(45deg) translate(35px, -55px);
        width: 155px;
        display: flex;
        align-items: center;
        justify-content: center;

        h4{
          margin-left: 15px;
          color: white;
          font-family: 'Rubik';
        }
      }
      a{
          text-decoration: none;
          p{
              border-radius: 4px;
              background: #EA3E2A;
              padding: 3px;  
              color: #FFF;
              font-family: 'Rubik';
              font-size: 11.063px;
              font-style: normal;
              font-weight: 500;
              line-height: 144%;  
              max-width: 170px;
              text-align: center;                   
          }
      }
      p{
          border-radius: 4px;
          background: #EA3E2A;
          padding: 3px;  
          color: #FFF;
          font-family: 'Rubik';
          font-size: 11.063px;
          font-style: normal;
          font-weight: 500;
          line-height: 144%;  
          max-width: 170px;
          text-align: center;                   
      }
  }
  .tarifCardBottom_block{
      padding: 20px;
      .tarifCardName_block{

          h3{
              color: #202020;
              font-family: 'Rubik';
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;    
          }
          p{
              color: #202020;
              font-family: 'Rubik';
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 153%; /* 153.846% */    
              margin-top: 5px;            
          }
      }
      .tarifCardInfo_block{
          margin-top: 18px;
          .tarifCardInfo_item{
              margin-top: 6px;
              display: flex;
              align-items: center;
              gap: 12px;
              img{
               width: 24px;
               height: 24px;
              }
              p{
                  color: #202020;
                  font-family: 'Rubik';
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 150% */
              }
          }
      }
      .tarifCardloadding_block{
          margin-top: 18px;
          border-radius: 4px;
          background: #E2E5EB;
          height: 4px;
          width: 85%;
          .tarifCardloadding_line{
              border-radius: 4px;
              background: #EA3E2A;
              height: 4px;                
          }
      }
      .tarifCardConnect_block{
          margin-top: 18px;
          display: flex;
          align-items: center;
          gap: 14px;
          .tarifCardConnect_item{
              max-width: 60px;
              display: flex;
              align-items: center;
              
              img{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                cursor: pointer;
                &:nth-child(1){ 
                  &:hover{
                      z-index: 2;
                  }
                }
                &:nth-child(2){
                  transform: translateX(-15px); 
                  &:hover{
                      z-index: 2;
                  }
                }
                &:nth-child(3){
                  transform: translateX(-27px);
                  &:hover{
                      z-index: 2;
                  }
                }

              }
          }
      }
      .tarifCardPrice_block{
          margin-top: 50px;
          .tarifCardPrice_item{
              display: flex;
              align-items: center;
              gap: 5px;
              h2{
                  color: #202020;
                  font-family: 'Rubik';
                  font-size: 19.531px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 122%;  
              }
              p{
                  color: #626C77;
                  font-family: 'Rubik';
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; 
                  text-decoration: line-through red; 
              }
          }
          h5{
              color: #626C77;
              font-family: 'Rubik';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 133.333% */        
              max-width: 215px;   
              margin-top: 5px;     
          }
      }
      .tarifCard_btn{
          margin-top: 13px;
      }
  }
  @media screen and(max-width: 420px) {
      max-width: 95%;
  }
}