.homeServicesCard_block{
    border-radius: 12px;
    background: #FFF;
    padding: 20px;
    max-width: 350px;
    min-height: 360px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .homeServicesCard_item{
        .img{
            width: 50px;
            height: 50px;
            color: #EA3E2A;
        } 
        h3{
            color: #202020;
            font-family: 'Rubik';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 120% */   
            margin-top: 20px;     
        }  
        p{
            color: #2E2E2E;
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%; 
            margin-top: 10px;     
        }
    }
    .homeServicesCard_btn{
        margin-top: 20px;
    } 
    @media screen and (max-width: 1124px) {
        max-width: none;
        width: 95%;
    }
    @media screen and (max-width: 768px) {
        max-width: none;
        width: 100%;
    }
}