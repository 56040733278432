.sksModule_container{
    background-color: #F2F3F7 ;
    .formModal_block{
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000000;
    }
}