.footerLinkMenu_block{
    padding: 30px 0px; 
    border-top: 1px solid rgba(255, 255, 255, 0.30); 
    border-bottom: 1px solid rgba(255, 255, 255, 0.30); 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    a{
        color: var(--color-Gray-Chateau, #969FA8);
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        text-decoration: none;
        &:hover{
            color: white;
            cursor: pointer;
        }     
    }
    
}