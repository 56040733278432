.servicesCards__block{
    width: 100vh;
    @media screen and (max-width: 1150px) {
        width: 400px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
 
    
}