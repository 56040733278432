.homeConnectCard_block{
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    min-height: 300px;
    margin-left: 20px;
   .homeConnectCard_number{
     padding: 10px;
     border-radius: 16px;
     background-color: #202020;
     width: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     h2{
        color: #FFFF;
        font-family: 'Rubik';
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 85.714% */                    
    }
   } 
   h3{
    color: #202020;
    font-family: 'Rubik';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 20px;  
   }
   p{
    color: #202020;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
    margin-top: 20px; 
   }
   .homeConnectCardBtn_block{
    margin-top: 20px;
   }
   @media screen and(max-width: 768px) {
     margin-left: 0;
   }
}