.directumLendingAddress_content{ 
    margin-top: 50px;
    .directumLendingAddress_block{
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .directumLendingAddress_item{
        border-left: 1px solid rgba(188, 195, 208, 0.50);
        padding: 30px 10px;
        &:nth-child(1){
          border: none;
        }
        width: 330px;
        h2{
          color: #1D2023;
          text-align: center;
          font-family: 'Rubik';
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 137.053% */
        }
        p{
          color: #EA3E2A;
          text-align: center;
          font-family: 'Rubik';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          margin-top: 20px;
          a{
            text-decoration: none;
            color: #EA3E2A;
            text-align: center;
            font-family: 'Rubik';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
      @media screen and(max-width: 768px) {
        flex-direction: column;
        width: 100%;
        .directumLendingAddress_item{
          width: 100%;
          border-left: none;
          border-top: 1px solid rgba(188, 195, 208, 0.50);
        }
      }
    }
  }