.aminMenuDataCardText_block{
    h3{
        color: #202020;
        font-family: 'Rubik';
        font-size: 15.412px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    p{
        color: #848484;
        font-family: 'Rubik';
        font-size: 11.008px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-top: 10px;  
    }
}