.homeEquipmentCard_block{
    border-radius: 24px; 
    background: #F2F3F7;
    padding: 15px 10px 0px 10px; 
    margin-top: 20px;
    width: 185px;
    // height: 185px;
    cursor: pointer;
    // background-image: url('../../../../Assets/Home/img/HomeEquipment/image-removebg-preview\ \(4\)\ 1.png');
    // background-size: 80%;
    background-position: bottom;
    background-repeat: no-repeat;
    &:hover{
        transform: scale(.98);
        }
        .homeEquipmentCard_item{
            h5{
            // padding-bottom: 80%;
            color: var(--sakh-mts-ru-mine-shaft, #3E3E3E);
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 142%; /* 142.5% */      
        }
    }
    img{
        width: 100%;
        // height: 100%;
        margin-top: 5px;
    }    
    @media screen and (max-width: 640px) { 
        width: 100%;
        .homeEquipmentCard_item{
            //  background-size: 90%;
            h5{
             font-size: 3.5vw;            
            }
        }
        //  img{
            
        //      width: 100%;
        //      height: 100%;
        //      max-height: 100px;
        //      object-fit: contain;
        //  }
    }
}
