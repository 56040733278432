.adminHome_container{
    background: #F7F7F7;
    padding: 0 20px 20px 20px;
    min-height: 100vh;
    
    .adminHome_block{
        max-width: 1200px;
        margin: 20px auto 0 auto;
        display: flex; 
        .adminHome_item{
            .adminHomeTopCard_block{
                margin-top: 20px;
                padding: 0 20px;
                display: flex;
                align-items: stretch;
                gap: 20px;
           
                .adminHomeTopCard_item{
                    width: 50%;
                }
            }
            .adminHomeTopCardBottom_block{
                margin-top: 20px;
                padding: 0 20px;
                display: flex;
                align-items: stretch;
                gap: 20px;
                width: 100%;
                .adminHomeTopCardBottom_item{ 
                     width: 40%;
                     &:nth-child(2){
                        width: 60%;
                     }
                }
            }
        }   
    }
    @media screen and(max-width: 1150px) {
        .adminHome_block{ 
            .adminHome_item{
                .adminHomeTopCard_block{
                    margin-top: 20px;
                    padding: 0 20px;
                    display: flex;
                    align-items: stretch;
                    flex-direction: column;
                    .adminHomeTopCard_item{
                        width: 100%;
                    }
                }
                .adminHomeTopCardBottom_block{  
                    flex-direction: column;
                    .adminHomeTopCardBottom_item{ 
                         width: 100%;
                         &:nth-child(2){
                            width: 100%;
                         }
                    }
                }
            }   
        }
    }
    @media screen and(max-width: 1000px) {
        padding: 20px 0 20px 0;
    }
    @media screen and(max-width: 750px) {
        padding: 20px 0 20px 0;
    }
}