.adminMenuLinkCard_block{
    display: flex; 
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
    border-radius: 16px;
    background: #FFF; 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);    
    margin-top: 20px;
    max-width: 300px;
    .acive{
        color: red;
    }
    .adminMenuLinkCard_link{
        text-decoration: none;

    }
}