.newsDescription_block {
  max-width: 1100px;
  margin: 0 auto;
    p {
      color: #202020;
      font-family: "Rubik";
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      margin-top: 20px;
    }
    .newsDescription_item {
      margin-top: 30px;
      h2 {
        color: #202020;
        font-family: "Rubik";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
      }
      ul {
        margin-top: 20px;
        margin-left: 40px;
        li {
          color: #202020;
          font-family: 'Rubik';
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 150%; 
          span {
            font-weight: 400;
          }
        }
      }
      p {
        color: #202020;
        font-family: 'Rubik';
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 
        margin-top: 20px;   
      }
    }
    @media screen and (max-width: 768px) {
        p{
            font-size: 20px;
        }
        .newsDescription_item {
            margin-top: 30px;
            h2 {
              font-size: 30px;
            }
            ul {
              margin-left: 20px;
              li {
                font-size: 20px;
              }
            }
            p {
              font-size: 20px;   
            }
          }
    }
  }