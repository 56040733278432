.newsBottomCard_block {
  max-width: 1100px;
  display: flex;
  align-items: start;
  // justify-content: space-between;
  gap: 50px;
  margin-top: 50px;
  cursor: pointer;
  .newsBottomCard_img {
    width: 300px;
    min-height: 300px;
    object-fit: contain;
  }
  .newsBottomCard_item {
    max-width: 490px;
    display: block;
    .newsBottomCardData_block {
      display: flex;
      align-items: center;
      gap: 20px;
      h6 {
        color: #626466;

        font-family: 'Rubik';
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .newsBottomCardData_item {
        display: flex;
        align-items: center;
        gap: 2px;
        h6 {
          color: #626466;

          font-family: 'Rubik';
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
    h2{
        color: #202020;
        font-family: 'Rubik';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        margin-top: 10px;       
    }
    p{
        margin-top: 20px;
        color: #B4AEAE;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;     
    }
  }
  @media screen and (max-width: 870px) {
    flex-direction: column;
    .newsBottomCard_img {
        width: 100%;
        min-height: auto;
      }
      .newsBottomCard_item {
        max-width: none;
         
     
      }
  }
}
