.vacanciesItemHeader_container {
  padding: 50px 20px;
  .vacanciesItemHeader_block {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .vacanciesItemHeader_item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 20px;
      .vacanciesItemHeader_title{
        h1{
            color: #1D2023;
            font-family: 'Rubik';
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;              
        }
        button{
            border-radius: 8px;
            background: #EA3E2A;
            width: 250px;
            height: 45px;
            border: none;
            cursor: pointer;
            outline: none;
            color: #FFF;
            text-align: center;
            font-family: 'Rubik';
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
            margin-top: 40px;           
            &:active{
                transform: scale(.98)
            }            
        }
      }
      .vacanciesItemHeader_text{
            margin-top: 50px;
          p {
            color: #1d2023;
            font-family: 'Rubik';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 150% */
            &:nth-child(2){
                margin-top: 20px;
            }
          }
      }
    }
    img{
        width: 45%;
    }
    @media screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        img{
            width: 70%;
        }
    }
    @media screen and (max-width: 768px) {
        .vacanciesItemHeader_item {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 20px;
            .vacanciesItemHeader_title{
              h1{ 
                  font-size: 36px;
             
              }
              
            }
 
          } 
        img{
            width: 80%;
        }
    }
    @media screen and (max-width: 480px) {
 
        img{
            width: 100%;
        }
    }
  }
}
