.adminHeader_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    .admin_logo{
        width: 170px;
    }
    .adminHeader_item{
        display: flex;
        align-items: center;
        gap: 20px;
        .adminHeaderIcon_block{
            border-radius: 1000px;
            background: rgba(25, 23, 28, 0.11);
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .adminHeaderNotificationIcon_block{
            position: relative;
            border-radius: 1000px;
            background: rgba(25, 23, 28, 0.11);
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // #F85F61
            .adminHeaderNotificationIcon_circle{
                position: absolute;
                top: 12px;
                right: 15px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #F85F61;
            }
        }
    }
}