.homeNews_container {
  padding: 50px 20px;
  .homeNews_block {
    max-width: 1200px;
    margin: 0 auto;
    .homeNews_title {
      h1 {
        color: #101828;
        font-family: "Rubik";
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        color: #202020;
        font-family: "Rubik";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 24px */
        margin-top: 16px;
      }
      @media screen and (max-width: 768px) {
        h1 {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }
  }
}
