.tarifCustomCard_block {
    width: 340px;
    min-height: 390px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);
    margin: 20px 0 20px 20px;
    img {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
    .tarifCustomCard_item {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 300px;
      .tarifCustomCardTop_text {
        h2 {
          color: #202020;
          font-family: "Rubik";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }
        p {
          color: #202020;
          font-family: "Rubik";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          margin-top: 10px;
        }
      }
      h3{
          color: #202020;
          font-family: 'Rubik';
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;     
      }
      .tarifCustomCardBottom_text{
          h2{
              color: #202020;
              font-family: 'Rubik';
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; 
              span{
                  font-size: 12px;
              }          
          }
          .tarifCustomCardBottom_btn{
              display: flex;
              align-items: center;
              gap: 20px;
              margin-top: 10px;
          }
      }
    }
    @media screen and (max-width: 420px) {
      width: 95%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    
  }
  