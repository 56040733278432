.whoisResponse_container{
    width: 100%;
    margin-top: 80px;
    h1{
        color: #202020;
        text-align: center;
        font-family: 'Rubik';
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;       
    }
    .whoisResponse_block{
        border-radius: 16px;
        background: #FFF; 
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);        
        width: 100%;
        padding: 10px;
        margin-top: 50px;
        padding: 30px 20px;
        .whoisResponse_item{
            max-width: 1000px;
            margin: 10px auto;
            padding: 10px 0;
            border-bottom: 1px solid #DEDFE0;
            .whoisResponseItem_title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                h2{
                    color: rgba(32, 32, 32, 0.80);
                    font-family: 'Rubik';
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;  
                    width: 95%;                  
                }
                .whoisResponse_icon{
                    color: #202020;
                    font-size: 24px;

                }
                cursor: pointer;
            }
            .whoisResponseText_block{
                padding: 20px;
                p{
                    color: #202020;
                    font-family: 'Rubik';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;                 
                }

            }
        }
    }
    @media screen and (max-width: 768px) {
        h1{
            font-size: 30px;
        }
        .whoisResponse_block{
            .whoisResponse_item{ 
                .whoisResponseItem_title{ 
                    h2{ 
                        font-size: 18px;  
                        width: 95%;                  
                    } 
                } 
            }
        }
    }
}