.refMenu_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    .refMenu_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 80%;
        .refMenu_item{
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: space-between;
            width: 80%;
            a{
                color: #202020;
                text-decoration: none;
                font-family: 'Rubik';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }
        }
    }
    .refMenuSearch_block{
        display: flex;
        align-items: center;
        gap: 20px;
        .refMenuSearch_item{
            display: flex;
            align-items: center;
            gap: 20px;
            p{
                color: #969FA8;
                font-family: 'Rubik';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;             
            }
        }
        button{
            border-radius: 8px;
            background: #EA3E2A;
            padding: 10px 15px;
            color: #FFF;
            font-family: 'Rubik';
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
            border: none;
            cursor: pointer;
            &:active{
                transform: scale(.98);
            }           
        }
    }
    @media screen and(max-width: 768px) {
        display: none;
     }
}