.businessAdvantagesCard_block{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    max-width: 260px;
   img{
    width: 50px;
    height: 50px;
   }
   p{
    color: #666;
    text-align: center;
    font-family: 'Rubik';
    font-size: 13.711px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;  
   } 
   @media screen and (max-width: 768px) {
     max-width: none;
     margin-top: 20px;
}
}