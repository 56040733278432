.vacanciesCards_container{
    padding: 50px 20px;
    background: #F8F8F8;
    .vacanciesCards_block{
        max-width: 1200px;
        margin: 0 auto;
        .vacanciesCards_title{
            h1{
                color: #202020;
                font-family: 'Rubik';
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 114%;  
            }
            p{
                color: #202020;
                font-family: 'Rubik';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;  
                margin-top: 20px;
            }
        }
        .vacanciesCards_item{
            margin-top: 50px;
        }
        @media screen and (max-width: 768px) {
            .vacanciesCards_title{
                h1{
                    font-size: 30px; 
                }
 
            }
        }
    }
}