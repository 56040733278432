.adminHistoryListItem_block{
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);;
    .adminHistoryListNameItem_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        h3{
            color: rgba(0, 0, 0, 0.87);
            font-family: 'Rubik';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;  
        }
        h2{
            color: rgba(0, 0, 0, 0.87);
            font-family: 'Rubik';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;  
        }
    }
    .adminHistorySubTitleItem_block{
        max-width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-top: 5px;
        @media screen and (max-width: 480px) {
            max-width: 250px;
        }
        .adminHistorySubTitleItem_item{
            display: flex;
            align-items: center;
            gap: 5px;
            .adminHistorySubTitleItem_circle{
              background-color: #EA3E2A;
              width: 5px;
              height: 5px;
              border-radius: 50%;  
            }
            p{
                color: rgba(0, 0, 0, 0.58);
                font-family: 'Rubik';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;                
            }
        }
        p{
            color: rgba(0, 0, 0, 0.58);
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;              
        }
    }
}