.homeIptvList_block{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    .homeIptvList_item{
        .homeIptvListitem_img{
            width: 50px;
            height: 50px;
            color: #EA3E2A;
        }
    }
    .homeIptvList_text{
        h2{
            color: var(--color-Shark, #1D2023); 
            font-family: 'Rubik';
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 100% */            
        }
        p{
            color: var(--sakh-mts-ru-shark, #1D2023); 
            font-family: 'Rubik';
            font-size: 16.313px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;          
        }
        @media screen and(max-width: 768px) {
            h2{
                font-size: 22px;
            }
        }
    }
}