.adminHistory_container{
    padding: 20px;
    .adminHistory_block{
        display: flex; 
        max-width: 1200px;
        margin: 0 auto;
        .adminHistory_card{
            padding: 20px;
        }
    }
    @media screen and (max-width: 1000px) {
        padding-top: 80px;
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        padding-top: 40px;
    }
}