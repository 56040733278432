.tarifsCards__block{ 
    .tarifs_title{
        display: flex;
        align-items: center; 
        gap: 20px;
        max-width: 800px;
        margin-top: 40px;
        .tarifsTitle_text{

            p{
                color: #202020;
                text-align: center;
                font-family: 'Rubik';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                white-space: nowrap;
                padding-bottom: 15px;
                cursor: pointer;             
            }
        }
        }
        @media screen and(max-width: 850px) {
            .tarifs_title{

                overflow-x: scroll;
            }
          h1{
            font-size: 30px;
            font-weight: 500;
          }
        }
        .tarifsCard__item{

 
            width: 820px;
            @media screen and (max-width: 1190px) {
                width: 800px;
            }
            @media screen and (max-width: 1170px) {
                width: 400px;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
        }
}