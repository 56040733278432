.homeNewsCard_container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 50px;
  .homeNewsCard_item {
    width: 420px;
    img {
      width: 100%;
    }
    .homeNewsCardDate_block {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      h6 {
        color: #626466;
        font-family: "Rubik";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .homeNewsCardDate_item {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 16px;
        }
      }
    }
    h2 {
      color: #202020;
      font-family: "Rubik";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
    }
    @media screen and (max-width: 1150px) {
        width: 60%;
    }
    @media screen and (max-width: 1000px) {
        width: 70%;
    }
  }
  .homeNewsCardText_block {
    .homeNewsCardText_item {
        border-top: 1px solid #D9D9D9;
        padding: 20px 0;
      .homeNewsCardTitle_block {
        display: flex;
        align-items: center;
        gap: 10px; 
        h6 {
          color: #626466;
          font-family: "Rubik";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .homeNewsCardDate_item {
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 16px;
          }
        }
      }
      h2 {
        color: #202020;
        font-family: "Rubik";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
      }
    }
    .homeNewsCardText_arrow{
        border-top: 1px solid #D9D9D9;
        padding: 20px 0;
        display: flex;
        align-items: center;
        gap: 13px;
        h3{
            color: #EA3E2A;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;            
        }
        .homeNewsCardTextArrow{
            font-size: 40px;
            color: #EA3E2A;
        }
    }
  }
  @media screen and(max-width: 900px) {
    flex-direction: column;
    .homeNewsCard_item {
        width: 100%;
    }
  }
}
