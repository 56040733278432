.notNtworkCard_block { 
   width: 100%;
   box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);    
   border-radius: 24px ;
  .notNtworkCardTitle_block {
    display: flex;
    align-items: stretch;
    .notNtworkCardTitle_item {
      width: 50%;  
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 24px 24px 0px 0px;
      background: #FFF; 
      box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);   
      padding: 20px;
      position: relative;   
      .notNtworkCardTitle_img {
        padding: 5px;
        background-color: #f3f3fa;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h4 {
        color: var(--Black-500---Main, #0f1121);
        font-family: 'Rubik';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; 
      }
    }
}
.notNtworkCard_item{
    border-radius: 0px 0px 24px 24px;
    background: #FFF; 
    position: relative;
    z-index: 10;
    padding: 20px;
   h4{
    display: none;
    font-size: 20px;
   }
    p{
        color: #0F1121;
        font-family: 'Rubik';
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;     
        margin-top: 10px;     
    }    
}
@media screen and (max-width: 900px) {
    .notNtworkCardTitle_block { 
        .notNtworkCardTitle_item {    
          h4 {
            display: none;
          }
        }
    }
    .notNtworkCard_item{ 
       h4{
        display: block; 
       }
             
    }  
}
}
