.notNetwork_container{
 padding: 20px;
 margin-bottom: 50px;
 .notNetwork_block{
    max-width: 1200px;
    margin: 0 auto;
    .notNetwork_logo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .notNetwork_logo{
            width: 20px;
        }
        img{
            width: 150px;
        }
        select{
            border: none;
            outline: none;
            font-size: 18px;
        }
    }
    .notNetwork_item{
        margin-top: 80px;
    }
    .notNetworkCards_block{
        margin-top: 50px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 20px;

    }
     @media screen and (max-width: 768px) {
        .notNetworkCards_block{
           flex-direction: column; 
    
        }
     }
 }
}