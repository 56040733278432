.newsTitle_block{
    max-width: 790px;
    margin: 0 auto;
    text-align: center;
    h1{
        color: #202020;
        font-family: 'Rubik';
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; 
        margin-top: 30px;   
    }
    p{
        color: #202020;
        text-align: center;
        font-family: 'Rubik';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        margin-top: 20px;
    }
    @media screen and (max-width: 768px) {
        h1{
            font-size: 30px;
        }
        p{
            font-size: 18px;
        }
    }
}