.vacanciesItemTextCard_block{
    margin-top: 50px;
    display: flex;
    justify-content: space-between; 
    gap: 30px;
    h2{
        color: #EA3E2A; 
        font-family: 'Rubik';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;         
    }
   .vacanciesItemTextCard_item{
     
     h2{
        color: #202020;
        font-family: 'Rubik';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;  
        margin-bottom: 20px;
     }
     .vacanciesItemTextCard_text{
      color: #202020;
         font-family: 'Rubik';
         font-size: 17px;
         font-style: normal;
         font-weight: 400;
         line-height: 150%; /* 150% */   
         margin-top: 10px;     
     } 
     
     width: 500px;
   
   } 
   @media screen and (max-width: 768px) {
      h2{
        font-size: 25px;
      }
      .vacanciesItemTextCard_item{
 
        h2{

           font-size: 25px; 
         
        }
    }
   }
   @media screen and (max-width: 480px) {
    flex-direction: column;
      h2{
         border: 1px solid;
         width: 45px;
         height: 35px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 8px;
      }
      .vacanciesItemTextCard_item{
         width: auto;
        h2{
            border: none;
            width: auto;
            height: auto;
            display: block;
        }
        ul{
             margin-left: 10px;
 
          }
    }
   }
}