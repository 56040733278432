*{
    box-sizing: border-box;
  }
  
  .directumLending_container{
    // padding: 0 20px;
    background-color: white;
    .directumLending_block{
      .directumLendingCard{
        background-image: url('../../Assets/Directum/img/IMAGE\ \(11\).png');
        background-repeat: no-repeat;
        background-size: cover;
        .directumLendingCard_block{
          max-width: 1000px;
          margin: 0 auto;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            margin-top: 40px;
          }
          h1{
            color: #383838;
            text-align: center;
            font-family: 'Rubik';
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            margin-top: 50px;
          }
          p{
            color: #383838;
            text-align: center;
            font-family: 'Rubik';
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 20px;
          }
          .directumLendingCard_btn{
            margin: 30px 0;
            button{
              border-radius: 10px;
              background: #FF8600;
              padding: 10px 20px;
              border: none;
              color: #FFF;
              text-align: center;
              font-family: 'Rubik';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              cursor: pointer;
              &:active{
                transform: scale(.98);
              }
            }
          }
          @media screen and(max-width: 768px) {
            img{
              width: 300px;
            }
            h1{
              font-size: 35px;
            }
  
          }
          @media screen and(max-width: 480px) {
            img{
              width: 80%;
            }
  
  
          }
        }
      }
      .directumLendingDescription_block{
        max-width: 1200px;
        margin: 120px auto 0 auto;
        padding: 0 20px;
        h1{
          color: #383838;
          font-family: 'Rubik';
          font-size: 38px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 53.2px */
        }
        .directumLendingDescription_item{
          margin-top: 20px;
          p{
            color: #383838;
            font-family: 'Rubik';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 14px;
          }
        }
        @media screen and(max-width: 768px) {
          margin-top: 50px;
          h1{
            font-size: 24px;
          }
          .directumLendingDescription_item{
            margin-top: 20px;
            p{
              font-size: 16px;
  
            }
          }
        }
      }
      .directumLendingBussinesCard_content{
        padding: 0 20px;
        // margin-top: 120px;
        .directumLendingBussinesCard_block{
          border-radius: 20px;
          background: #FFEBD5;
          max-width: 1200px;
          margin: 150px auto 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 20px;
  
          .directumLendingBussinesCardPhoto_block{
            position: relative;
            img{
              position: absolute;
              top: -90px;
              right: 50%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        h2{
          margin-top: 60px;
          color: #05184A;
          text-align: center;
          font-family: 'Rubik';
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          width: 90%;
        }
        .directumLendingBussinesCard_item{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 20px;
          width: 90%;
          margin: 20px auto;
  
          .directumLendingBussinesCardItem_block{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-direction: column;
            width: 200px;
            p{
              color: #000;
              text-align: center;
              font-family: 'Rubik';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            }
          }
        }
        @media screen and (max-width: 768px) {
          h2{
            font-size: 30px;
          }
        }
        .directumLendingBussinesCard_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          button{
            border-radius: 7px;
            background: #3C65CC;
            color: #FFF;
            text-align: center;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 100% */
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            &:active{
              transform: scale(.98);
            }
          }
        }
      }
      .directumLendingProduct_block{
        max-width: 1200px;
        margin: 120px auto 70px auto;
        padding: 0 20px;
        h1{
          color: #383838;
          text-align: center;
          font-family: 'Rubik';
          font-size: 38px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 53.2px */
        }
        .directumLendingProduct_item{
          margin-top: 50px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;
          .directumLendingProductCard_block{
            max-width: 300px;
            display: flex;
            align-items: center;
            // justify-content: center;
            flex-direction: column;
            border-radius: 5.16px;
            border: 1.29px solid #DDD;
            background: #F5F8FD;
            padding: 20px;
            gap: 20px;
            .directumLendingProductCardImg_block{
              height: 190px;
  
            }
            p{
              color: #000E20;
              text-align: center;
              font-family: 'Rubik';
              font-size: 20.639px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 140.001% */
              min-height: 116px;
            }
            button{
              border-radius: 9.03px;
              border: 1.29px solid #E4E7EA;
              padding: 10px 20px;
              color: #666;
              text-align: center;
              font-family: 'Rubik';
              font-size: 19.349px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 140% */
              cursor: pointer;
              &:active{
                transform: scale(.98);
              }
            }
          }
          @media screen and (max-width: 768px) {
            justify-content: center;
          }
        }
      }
      .hostingResponse_container{
        padding: 0 20px 70px 20px;
        .hostingResponse_block{
            max-width: 1200px;
            margin: 0 auto;
        }
    }
    
    }
  }
  