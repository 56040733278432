.businessFAQ_container{
    padding: 50px 20px 20px 20px;
    .businessFAQ_block{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: stretch; 
        justify-content: space-between;
        gap: 20px;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
        }
    }
}