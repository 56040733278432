.businessDiscountCard_block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 110px; 
    gap: 10px;
    .businessDiscountCard_item{
        width: 92px;
        height: 92px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--moskva-mts-ru-athens-gray, #F2F3F7);
        img{
            width: 40px;
        }
    }
    p{
        color: var(--moskva-mts-ru-shark, #1D2023);
        text-align: center;
        font-family: 'Rubik';
        font-size: 14.625px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        margin-top: 10px;
    }
    @media screen and (max-width: 561px) {
        width: 40%;
        max-width: none;
    }
}