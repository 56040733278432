.adminHomeTarrifCard_block{
    padding: 20px;
    border-radius: 24px;
    background: #FFF; 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14); 
    height: 100%;   
    .adminHomeTarrifCardTop_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .adminHomeTarrifCardTop_item{
           h1{
            color: #000;
            font-family: 'Rubik';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;        
           }
           h2{
            color: #202020;
            font-family: 'Rubik';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; 
            margin-top: 10px; 
           } 
        }
    }
    p{
        color: #202020;
        font-family: 'Rubik';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 153.846% */   
        margin-top: 10px;         
    }
    .adminHomeTarrifCardSpeed_block{
        margin-top: 14px;
        .adminHomeTarrifCardSpeed_item{
            display: flex;
            align-items: center;
            gap: 10px;
            h3{
                color: #202020;
                font-family: 'Rubik';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;                  
            }
        }
        .tarifCardloadding_block{
            margin-top: 18px;
            border-radius: 4px;
            background: #E2E5EB;
            height: 4px;
            width: 85%;
            .tarifCardloadding_line{
                border-radius: 4px;
                background: #EA3E2A;
                height: 4px;                
            }
        }
    }
    .adminHomeTarrifCardBalance_text{
        margin-top: 20px;
        h6{
            color: rgba(0, 0, 0, 0.58);
            font-family: 'Rubik';
            font-size: 11.008px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;  
        }
        h2{
            color: #202020;
            font-family: 'Rubik';
            font-size: 19.531px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; 
        }
    }
}