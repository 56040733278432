.whoisActiveResult_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 16px;
    background: #FFF; 
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);   
    margin-top: 50px; 
    h2{
        color: #202020;
        font-family: 'Rubik';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;    
    }
    p{
        border-radius: 8px;
        background: #E7F9EA;
        padding: 10px;
        color: #16C733;
        text-align: center;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;       
    }
    .whoisActiveResult_item{
        display: flex;
        align-items: center;
        gap: 20px;
        h1{
            color: #202020;
            text-align: center;
            font-family: 'Rubik';
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 111%;  
        }
        .whoisActiveResult_btn{
            width: 159px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        .whoisActiveResult_item{
            flex-direction: column;
            width: 100%;
            h1{ 
                font-size: 30px;   
            }
            .whoisActiveResult_btn{
                width: 100%
            }
        }
    }
}