.notNtworkItem_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .notNtworkItem_item{
        .notNetwork_text{
        
            max-width: 900px;
            &:nth-child(2){
                margin-top: 20px;
            }
          p{
            color: #202020;
            font-family: 'Rubik';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;          
          }
          h4{
            color: #202020;
            font-family: 'Rubik';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;            
          }
          @media screen and (max-width: 1000px) {
            p{
                font-size: 20px;
              }
              h4{
                font-size: 20px;
          
              }
          }
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 60px;
    }
}