.videoServiceItem_block{
    border-radius: 8px;
    border: 1px solid rgba(188, 195, 208, 0.50);
    background: #F2F3F7;
    padding: 20px; 
    margin-left: 20px;   
    h2{
        color: #202020;
        font-family: 'Rubik';
        font-size: 27px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;  
    }
    p{
        color: #626C77;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 20px;  
    }
    @media screen and (max-width: 768px) {
        margin-left: 0;
        h2{
            font-size: 25px;
        }
    }
}