.adminHistoryList_block{
    border-radius: 24px;
    background: #FEFEFE; 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14); 
    padding: 20px 0;   
    width: 500px; 

    .adminHistoryList_title{
      display: flex; 
      padding: 0 20px;
      h3{
          color: #202020;
          font-family: 'Rubik';
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;            
        span{
            color: #202020;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;            
        }

      }
      select{
        color: #EA3E2A;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;     
        border: none;
        margin-top: 5px;
        outline: none;
      }
    }
    .adminHistoryList_item{
        margin-top: 10px;
        max-height: 300px;
        overflow-y: scroll;
        padding: 0 20px;
    }
    @media screen and (max-width: 1000px) {
         width: 100%;
    }
}