.homeConnect_container { 
  padding: 50px 20px;
  background-color: #F2F3F7;
  .homeConnect_block {
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      color: #202020;
      font-family: "Rubik";
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 85.714% */
    }
    .homeConnect_item{
     margin-top: 50px;
    }
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
  .form_container{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    z-index: 10000000;
    .form_block{
        max-width: 1200px;
    }
}
}
