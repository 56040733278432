.sksTasksSmallCard_block{ 
 
    width: 285px;
    min-height: 315px;
    background: #FFF;
    border-radius: 16px;
    padding: 20px;
    h3{
        color: #202020;
        font-family: 'Rubik';
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-top: 40px;
        min-height: 80px;
        display: flex;
        align-items: flex-end;
    }
    p{
        color: #202020;
        font-family: 'Rubik';
        font-size: 13.242px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;  
        margin-top: 10px;       
    }
    @media screen and (max-width: 1239px) {
        width: 32%;
    }
    @media screen and (max-width: 1039px) {
        width: 31%;
    }
    @media screen and (max-width: 934px) {
        width: 48%;
    }
    @media screen and (max-width: 768px) {
        h3{
            font-size: 20px;
        }
    }
    @media screen and (max-width: 550px) {
        width: 47%;
    }
    @media screen and (max-width: 530px) {
        width: 100%;
    }
}