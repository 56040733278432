.sksProductTitle_content{
    width: 60%;
    h1{
        color: #202020;
        font-family: 'Rubik';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;  
    }
    .sksProductTitle_block{
        margin-bottom: 50px;
        margin-top: 20px;
        h1{
            color: #202020;
            font-family: 'Rubik';
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;  
        }
        .sksProductTitle_btn{
            margin-top: 30px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 20px;
            border-radius: 8px;
            background: #F95632;
            max-width: 240px;
            cursor: pointer;
            &:active{
                transform: scale(.98);
            }   
            p{
                color: #FFF;
                text-align: center;
                font-family: 'Rubik';
                font-size: 13.875px;
                font-style: normal;
                font-weight: 400;
                line-height: 144%; 
            }
            .sksProductTitleBtn_arrow{
                color: #FFF;
                font-size: 24px;
            }     
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
    @media screen and (max-width: 768px) { 
          h1{
            font-size: 36px;
          }
          .sksProductTitle_block{
            h1{
                font-size: 30px;
            }

          }
    }
}