.header__subtitle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    z-index: 99;
    background-color: white;
    padding: 10px 0;
    display: none;

    .header__container {
      width: 95%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      gap: 20px;
      .searchBasket_block{
        position: relative;
        img{
            
        }
        .searchText{
            position: absolute;
            top: -2px;
            width: 15px;
            height: 15px;
            border: 1px solid #E9292F;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 1000;
            right: -6px;
            background: #FAFAFA;
            p{
                color: #E9292F;
                font-family: 'Poppins';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;                        
            }
        }

    }
     .search_block{
        display: flex;
        align-items: center;
        gap: 10px;
        width: 500px;
        height: 20px;
        padding: 10px 20px;
        border-radius: 12px;
        background: rgba(231, 231, 231, 0.63);
        input{
            width: 100%;
            height: 20px;
            border-radius: 14px;
            border: none;
            outline: none;
            background: none;
            height: 100%;
            color: #E9292F;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            // line-height: 100%;
            &::placeholder{
                color: #E9292F;
            } 
        }

        img{
            width: 15px;

        }
     }
      .header__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        // display: none;
        // width: 120px;
        img{
          width: 150px;
        //   width: 100%;
          cursor: pointer;
        //   margin: 6px 0;
          // &:nth-child(2){
          //   object-fit: cover;
          // }
        }
      }

      .header__btn {
        display: flex;
        align-items: center;
        width: 70%;

        a {
          text-decoration: none;
          margin-left: 20px;

          h3 {
            font-family: 'Raleway', sans-serif, 'Roboto';
            font-weight: 700;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            margin: 0;

            &:not(:first-child) {
              margin-left: 30px;
            }

            &:hover {
              color: #EA3E2A;
            }
          }
        }


              display: none;

      }
      .headerHosting__btn {
        display: flex;
        align-items: center;
        width: 70%;

        a {
          text-decoration: none;
          margin-left: 20px;

          h3 {
            font-family: 'Raleway', sans-serif, 'Roboto';
            font-weight: 700;
            font-size: 16px;
            color: #6B6468;
            cursor: pointer;
            margin: 0;

            &:not(:first-child) {
              margin-left: 30px;
            }

            &:hover {
              color: #EA3E2A;
            }
          }
        }



          display: none;

      }

      .header__signIn {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #363636;
        width: 258px;
        button {
          background: #EA3E2A;
          color: #fff;
          border: none;
          font-family: 'Raleway', sans-serif, 'Roboto';
          font-weight: 700;
          font-size: 20px;
          padding: 10px 0px;
          border-radius: 30px;
          width: 50%;
          cursor: pointer;

          &:active {
            transform: scale(.98);
          }
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .menu__block {
        width: 50px;
        height: 48px;
        // margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        z-index: 1000;
      }

      /* скрываем чекбокс */
      #menu__toggle {
        opacity: 0;
      }

      /* стилизуем кнопку */
      .menu__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 35px;
        height: 35px;
        cursor: pointer;
        z-index: 3;
        position: relative;
        top: -16px;
      }

      /* добавляем "гамбургер" */
      .menu__btn>span,
      .menu__btn>span::before,
      .menu__btn>span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #E9292F;
      }

      .menu__btn>span::before {
        content: '';
        top: -8px;
      }

      .menu__btn>span::after {
        content: '';
        top: 8px;
      }

      /* контейнер меню */
      .menu__box {
        display: block;
        position: fixed;
        visibility: hidden;
        top: 0;
        right: -100%;
        width: 80%;
        height: 50%;
        margin: 0;
        padding: 0px 0;
        list-style: none;
        background-color: #F2F3F7;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        z-index: 2;
        border-bottom-left-radius: 30px;
        overflow-y: scroll;

        li {
          text-align: center;
          // margin-left: 20px;
          margin-top: 20px;
   
          .menuText_mobi {
          
              color: #969FA8;
              font-family: 'Rubik';
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 148.664%; 
         
          }
          .topMenuRef_item{
            display: flex;
            align-items: center;
            gap: 5px;
            position: relative;
            .topMenuRef_icon{
                color: #969FA8; 
            }
            .topMenuRef_group{
                position: absolute;
                padding: 20px 20px 20px 10px;  
                background: white; 
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
                top: 30px;
                right: -30px;
                border-radius: 16px; 
                display: flex;
                flex-direction: column;
                gap: 10px;
                z-index: 1000000;
            }
        }
          .active{
            font-size: 20px;
            font-family:  sans-serif, 'Roboto';
            color: #EA3E2A;
            font-weight: 400;
            cursor: pointer;
            text-decoration: none;

          }
          .linkItem{
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 10px;
            .menuTextItem_mobi{
              text-decoration: none;
              font-size: 18px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              font-family:  sans-serif, 'Roboto';
              color: black;
              font-weight: 400;
              cursor: pointer;
              text-decoration: none;
              width: 100%;
              height: 40px;
            }
          }
        }
        // .menuContactMobi_block{
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-end;
        // //   height: 100%;
        // margin-top: 80px;
        //   flex-direction: column;
        //  P{
        //   margin: 0;
        //   color: #252729;
        //   font-family: 'Roboto';
        //   font-size: 16px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: 28px;
        //  }
        //  .numberMobi_block{
        //    display: flex;
        //    align-items: center;
        //    gap: 10px;
        //    img{
        //     width: 20px;
        //    }
        //    h2{
        //     color: #252729;
        //     font-family: 'Roboto';
        //     font-size: 16px;
        //     font-style: normal;
        //     font-weight: 700;
        //     line-height: 28px
        //    }
        //  }
        // }
        .menu__box__top{
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          padding: 70px 20px 0 20px;
          background-color: white;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          .active{
            border-bottom: 2px solid #EA3E2A;
          }
          .menuMobi_ref{
            color: #969FA8;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 148.664%;   
            text-decoration: none;    
            margin: 0;     
            padding-bottom: 8px;
          }
          .topMenuRef_item{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0px; 
            text-align: center;
            margin-bottom: 8px;
            position: relative;
            p{
              color: #969FA8;
              font-family: 'Rubik';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 148.664%;   
            }
            .topMenuRef_group{
              position: absolute;
              padding: 20px 20px 20px 10px;  
              background: white; 
              box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
              width: 230px;
              top: 30px;
              right: 0px;
              border-radius: 16px; 
              display: flex;
              flex-direction: column;
              gap: 10px;
              z-index: 10;
              .active{
                color: #EA3E2A;
              }
              
            }
            .topMenuRef_icon{
                color: #969FA8; 
            }
        }
        }
        .menu__box__bottom{
          margin-top: 10px;
          background-color: white;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;  
          padding: 20px;  
          height: 100%;  
          h1{
            color: #202020;
            font-family: 'Rubik';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 148.664%;   
            text-decoration: none;  
            margin-bottom: 20px;          
          }  
          .menu__box__bottom_item{
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 10px;
            .menu__box__bottom_item_icon{

              color: #969FA8;
              font-size: 20px;
            }
            a{
              color: #969FA8;
              font-family: 'Rubik';
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 148.664%;   
              text-decoration: none;
              text-decoration: none;
            } 
          } 
        }
        @media screen and (max-width:768px) {
            width: 100%;
            height: 100%;
        }
      }

      // .menu__box img {
      //   width: 300px;
      //   margin-top: 150px;
      // }

      /* элементы меню */
      .menu__item {
        display: block;
        color: #fff;
        font-family: 'Raleway', sans-serif, 'Roboto';
        font-size: 20px;
        font-weight: 300;
        text-decoration: none;
        padding: 5px;
        text-align: left;
      }

      .menu__item:hover {
        color: #EA3E2A;
      }

      .menu__item:active {
        transform: scale(.7);
      }

      #menu__toggle:checked~.menu__btn>span {
        transform: rotate(45deg);
      }

      #menu__toggle:checked~.menu__btn>span::before {
        top: 0;
        transform: rotate(0);
      }

      #menu__toggle:checked~.menu__btn>span::after {
        top: 0;
        transform: rotate(90deg);
      }

      #menu__toggle:checked~.menu__box {
        visibility: visible;
        right: 0;
      }


      .menu__btn>span,
      .menu__btn>span::before,
      .menu__btn>span::after {
        transition-duration: .25s;
      }

      .menu__box {
        transition-duration: .25s;
      }

      .menu__item {
        transition-duration: .25s;
      }


      @media screen and (max-width: 768px) {
        .header__logo {
        //   display: block;
        //   width: 80px;
        }

        .menu__block {
          display: block;
        }
      }
    }
    @media screen and (max-width: 768px) {
     display: block;
    }

  }