input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
.adminLoginCard_block{
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    padding: 20px 30px;
    width: 400px;
    min-height: 380px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    img{
      width: 170px;  
    }
    h2{
        margin-top: 20px;
        font-family: 'Rubik';
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;  
        text-decoration-skip-ink: none;
        max-width: 242px;
    } 
    .adminLoginCard_item{
        margin-top: 10px;
        input{
            margin-top: 10px;
            width: 100%;
            border-radius: 8px;
            border: 2px solid rgba(242, 243, 247, 1);
            height: 40px;
            font-family: 'Rubik';
            font-size: 16px;
            font-weight: 400; 
            text-indent: 10px;
            outline: none;
        }
        p{
           color: rgba(234, 62, 42, 1); 
           margin: 2px;
        }
        button{
            margin-top: 20px;
            border-radius: 8px;
            background: rgba(234, 62, 42, 1);
            height: 50px;
            border: none;
            width: 100%;
            cursor: pointer;
            font-family: 'Rubik';
            font-size: 16px;
            font-weight: 400;
            line-height: 18.96px;
            color: white; 
            &:active{
                transform: scale(.98);
            }
        }
    }
    @media screen and (max-width: 430px) {
        width: 100%;
        h2{ 
            font-size: 20px; 
        } 
    }
}

