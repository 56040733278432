.businessAdvantages_container{
    padding: 50px 20px;
    .businessAdvantages_block{
        max-width: 1200px;
        margin: 0 auto;
        h3{
            color: #202020;
            text-align: center;
            font-family: 'Rubik';
            font-size: 22.688px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%;            
        }
        .businessAdvantages_item{
            margin-top: 50px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 20px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}