.tarifsHosting_contetnt {
    max-width: 1200px;
    margin: 50px auto 0 auto;
    padding: 20px;
    background-color: white;
  
    .tarifsHosting_title {
      max-width: 900px;
  
      h1 {
        color: #202020;
        font-size: 48px;
        padding-top: 30px;
        font-family: 'Rubik';
      }
  
      p {
        margin-top: 20px;
        color: #202020;
        font-size: 20px;
        font-family: 'Rubik';
        line-height: 120%;
      }
    }
  
    .tarifsHosting_block {
      margin-top: 70px;
  
      table {
        width: 100%;
        margin: 20px auto;
        text-align: center;
  
        th {
          background-color: #F2F3F7;
          color: #202020;
          font-size: 20px;
          font-family: 'Rubik';
          font-weight: 700;
          text-transform: uppercase;
          width: 33%;
          padding: 10px 0;
          span {
            color: #202020;
            text-align: center;
            font-size: 14px;
            font-family: 'Rubik';
            font-style: italic;
            font-weight: 300;
            line-height: 36px;
          }
        }
  
        td {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-family: 'Rubik';
          font-weight: 300;
          text-transform: capitalize;
          background: #F2F3F7;
          padding: 10px 0;
        }
   
        tr:nth-child(odd) td {
            background-color: #FFFFFF;
        }
        
        tr:nth-child(even) td {
            background-color: #F2F3F7;
        }
      }
    }
  
    @media screen and (max-width: 768px) {
      .tarifsHosting_title {
        h1 {
          font-size: calc(22px + 14 * (100vw / 1200));
        }
  
        p {
          font-size: calc(16px + 2 * (100vw / 1200));
        }
      }
  
      .tarifsHosting_block {
        table {
          width: 100%;
          margin: 20px auto;
          text-align: center;
  
          th {
            color: #202020;
            font-size: calc(12px + 6 * (100vw / 1200));
            font-family: 'Rubik';
            font-weight: 700;
            text-transform: uppercase;
            width: 33%;
  
            span {
              color: #202020;
              text-align: center;
              font-size: 12px;
              font-family: 'Rubik';
              font-style: italic;
              font-weight: 300;
              line-height: calc(12px + 14 * (100vw / 1200));
            }
          }
  
          td {
            color: #000;
            text-align: center;
            font-size: 18px;
            font-family: 'Rubik';
            font-weight: 300;
            text-transform: capitalize;
            padding: 10px 0;
          }
        }
      }
    }
  }
  