.slider_ {
  padding: 0 20px;
  .form_container{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    z-index: 10000000;
    .form_block{
        max-width: 1200px;
    }
}
  }
  .slider-container {
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.progress-indicators {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 10;
}

.progress-bar {
  flex-grow: 1;
  height: 4px;
  border-radius: 5px;
  background: #ceddf1;
  margin: 0 2px;
  transition: width 0.1s linear;
}

.progress-bar.active {
  background-color: white;
}

.slider {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  color: #fff;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;
}

.slider-content {
  position: relative;
  flex: 1;
  z-index: 1;
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.slider-content h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.slider-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slider-content ul li {
  margin-bottom: 5px;
}

.slider-button {
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 150px;
  color: var(--moskva-mts-ru-black, #000);
  text-align: center;
  font-family: 'Rubik';
  font-size: 16.07px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  &:hover{
    opacity: .7;
  }
}

.slider-controls {
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 20;
}
.slider-controls-item {
  padding: 10px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider-control {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
}
.left {
  color: #000000;
}
.right {
  color: #000000;
}

.progress-indicators {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 10;
}

.progress-bar-wrapper {
  flex-grow: 1;
  height: 4px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 2px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 5px;
  background-color: white;
  transition: width 0.1s linear;
}
.slider-text {
  margin-top: 30px;
  max-width: 550px;
  h1 {
    color: var(--moskva-mts-ru-nero, #fff);
    font-family: "Rubik";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 100% */
  }
  img{
    width: 100%;
  }
  ul {
    li {
      color: var(--moskva-mts-ru-nero, #fff);
      font-family: "Rubik";
      font-size: 18.906px;
      font-style: normal;
      font-weight: 500;
      line-height: 126%; /* 126.942% */
    }
  }
}
.img_blok{
  flex: 1;
  img{
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .slider_{
    padding: 30px 0 0 0;
    .slider-container{
      border-radius: 0;
      .slider{
        flex-direction: column-reverse;
        height: auto;
        .slider-image{
           
           object-fit: none;
           width: 100%; 
        }
        .slider-content{
          padding: 0px 20px 20px 20px;
          .slider-text{
            h1{
              font-size: calc(25px + 3 * (100vw / 1200));
            }
            ul{
              margin-bottom: 20px;
              li{
                font-size: 16px;

              }
            }
          }
          .slider-button{
            font-size: 14px;
          }
        }
        .img_blok{
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 80%;
            height: 80%;
          }
        }
      }
    }
  }
}
