.adminHomePackages_block{
    border-radius: 24px;
    background: #FFF; 
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);   
    padding: 20px;
    height: 100%;
    .adminHomePackagesTop_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px; 
        h1{
            color: #000;
            font-family: 'Rubik';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;            
        }
    } 
    .adminHomePackages_item{
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }
    @media screen and (max-width: 1150px) {
        .adminHomePackages_item{
            flex-direction: column;
        }
    }
    @media screen and (max-width: 420px) {
         .adminHomePackagesTop_block{
            flex-wrap: wrap;
         }
    }
}