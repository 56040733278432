.vpsSliderCard_block{
    width: 350px;
    border-radius: 24px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 6px 24px 0px rgba(15, 34, 79, 0.14);
    margin: 20px auto;
    .vpsSliderCardTop_block{
        background-size: cover;
        background-repeat: no-repeat;
        height: 100px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 20px; 
        p{
            border-radius: 4px; 
            padding: 3px;  
            color: #FFF;
            font-family: 'Rubik';
            font-size: 11.063px;
            font-style: normal;
            font-weight: 500;
            line-height: 144%;  
            max-width: 170px;
            text-align: center;                   
        }
    }
    .vpsSliderCardBottom_block{
        padding: 20px;
        .vpsSliderCardName_block{

            h3{
                color: #202020;
                font-family: 'Rubik';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;    
            }
            p{
                color: #202020;
                font-family: 'Rubik';
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 153%; /* 153.846% */    
                margin-top: 5px;            
            }
        }
        .vpsSliderCardInfo_block{
            margin-top: 18px;
            .vpsSliderCardInfo_item{
                margin-top: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
               
                p{
                    color: #202020;
                    font-family: 'Rubik';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 150% */
                }
            }
        }

        .vpsSliderCardPrice_block{
            margin-top: 50px;
            .vpsSliderCardPrice_item{
                display: flex;
                align-items: center;
                gap: 5px;
                h2{
                    color: #202020;
                    font-family: 'Rubik';
                    font-size: 19.531px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 122%;  
                }
                p{
                    color: #626C77;
                    font-family: 'Rubik';
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;  
                }
            }
            h5{
                color: #626C77;
                font-family: 'Rubik';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 133.333% */        
                max-width: 215px;   
                margin-top: 5px;     
            }
        }
        .vpsSliderCard_btn{
            margin-top: 13px;
        }
    }
    @media screen and(max-width: 420px) {
        max-width: 95%;
    }
}