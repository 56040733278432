.sksProduct_container{
    margin-top: 120px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        margin-top: 50px;
    }
}