.sksAdvantages_container{
    margin-top: 50px;
    h1{
        color: #202020;
        text-align: center;
        font-family: 'Rubik';
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;  
    }
    .sksAdvantages_block{
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    @media screen and (max-width: 768px) {
        margin-top: 30px;
        h1{
            font-size: 30px;
        }
        .sksAdvantages_block{
            margin-top: 50px; 
        }
    }
}