.sksTasksBigCard_block{
    background-image: url('../../../../Assets/Sks/img/Picture\ →\ 33317-ustanovit-biznes-prilojzeniya.jpg.png');
    min-height: 315px;
    width: 590px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px; 
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    h3{
        color: #FFF; 
        font-family: 'Rubik';
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;   
    }
    p{
        color: #FFF;
        font-family: 'Rubik';
        font-size: 13.242px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;  
        margin-top: 10px;       
    }
    @media screen and (max-width: 1239px) {
        width: 100%;
    }
}