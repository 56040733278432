.terms_container {
  padding: 20px;
  .terms_block {
    max-width: 1200px;
    margin: 50px auto;
    .terms_item {
      margin-top: 50px;
      h1 {
        font-size: 36px;
        color: #202020;
        text-align: center;
        margin-top: 50px;
      }
      p {
        font-size: 16px;
        color: #202020;
        margin-top: 20px;
        span {
          font-weight: bold;
        }
      }
      ul {
        margin-left: 40px;
        font-size: 16px;
        color: #202020;
        margin-top: 20px;
        li {
          margin-top: 10px;
        }
      }
      @media screen and(max-width: 768px) {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}
