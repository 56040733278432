.homeIptv_container{
   padding: 50px 20px 100px 20px; 
   .homeIptv_block{
    max-width: 1200px;
    margin: 0 auto;
    .homeIptv_title{
        display: flex;
        align-items: center;
        gap: 12px;
        h1{
            color: #202020;
            font-family: 'Rubik';
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 85.714% */                    
        }
        .homeIptv_arrow{
            width: 30px;
            height: 30px;
            margin: 0;
            padding: 0;
            vertical-align: middle;
             
        }
        @media screen and (max-width: 768px) {
            h1{
                font-size: 30px;
                font-weight: 500;
            }
            .homeIptv_arrow{
                width: 20px;
                height: 20px;
            }
        }
    }
    .homeIptv_item{
        display: flex;
        margin-top: 50px;
        gap: 20px;
        .homeIptvLists_block{ 
            overflow-y: scroll;
            padding: 10px;
            width: 40%;
            max-height: 500px;
            .homeIptvLists_item{
                cursor: pointer;
                padding: 20px;
            }
        }
        @media screen and (max-width: 1010px) {
            flex-direction: column;
            gap: 40px;
            .homeIptvLists_block{ 
                overflow-y: scroll;
                padding: 10px;
                width: 100%;
                max-height: 500px;
 
            }
        }
    }
    .homeIptv_btn{
        margin-top: 20px;
    }
   }
   .form_container{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    z-index: 10000000;
    .form_block{
        max-width: 1200px;
    }
}
}