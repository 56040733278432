.homeApplicationForm_block{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
    .homeApplicationForm_input{
        
       p{
        color: #FFF;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        margin-bottom: 10px;        
       } 
       input{
        border-radius: 8px;
        border: 1px solid #252525;
        background: #FFF;
        width: 314px; 
        height: 40px;
        text-indent: 10px;
        color: #202020;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        outline: none;        
       }
       .homeApplicationFormInput_item{
         display: flex;
         align-items: center;
         border-radius: 8px;
         border: 1px solid #252525;
         background: #FFF;
         height: 40px;
         width: 314px;
         padding-left: 10px;
         p{
            color: #202020;
            font-family: 'Rubik';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;   
            margin: 0;       
         } 
         input{
          background: none; 
          height: 100%; 
          border: none;
          outline: none;
          color: #202020;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;          
         }        
       }
       }
        button{
         border-radius: 8px;
         border: 1px solid #252525;
         background: #6C7279;
         width: 150px;
         height: 40px; 
         color: #FFF;
         font-family: 'Rubik';
         font-size: 14px;
         font-style: normal;
         font-weight: 300;
         line-height: 150%; 
         cursor: pointer;
         &:active{
            transform: scale(.98);
         }
        }
        @media screen and(max-width: 870px) {
            flex-direction: column;
            align-items: center;
            .homeApplicationForm_input{
        
                p{
                 color: #FFF;
                 font-family: 'Rubik';
                 font-size: 14px;
                 font-style: normal;
                 font-weight: 300;
                 line-height: 150%;
                 margin-bottom: 10px;        
                } 
                input{
                 border-radius: 8px;
                 border: 1px solid #252525;
                 background: #FFF;
                 width: 400px; 
                 height: 40px;
                 text-indent: 10px;
                  
                }
                .homeApplicationFormInput_item{
                  display: flex;
                  align-items: center;
                  border-radius: 8px;
                  border: 1px solid #252525;
                  background: #FFF;
                  height: 40px;
                  width: 400px;
                  padding-left: 10px;
                  p{
                     color: #202020;
                     font-family: 'Rubik';
                     font-size: 14px;
                     font-style: normal;
                     font-weight: 400;
                     line-height: 150%;   
                     margin: 0;       
                  } 
                  input{
                   background: none; 
                   height: 100%; 
                   border: none;
                   outline: none;
                   
                  }        
                }
                }
                 button{
                  border-radius: 8px;
                  border: 1px solid #252525;
                  background: #6C7279;
                  width: 150px;
                  height: 40px; 
                  color: #FFF;
                  font-family: 'Rubik';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 150%; 
                  cursor: pointer;
                  &:active{
                     transform: scale(.98);
                  }
                 }
        }
        @media screen and(max-width: 480px) {
            flex-direction: column;
            align-items: center;
            .homeApplicationForm_input{
                width: 100%;
                p{
                 color: #FFF;
                 font-family: 'Rubik';
                 font-size: 14px;
                 font-style: normal;
                 font-weight: 300;
                 line-height: 150%;
                 margin-bottom: 10px;        
                } 
                input{
                 border-radius: 8px;
                 border: 1px solid #252525;
                 background: #FFF;
                 width: 100%; 
                 height: 40px;
                 text-indent: 10px;
                  
                }
                .homeApplicationFormInput_item{
                  display: flex;
                  align-items: center;
                  border-radius: 8px;
                  border: 1px solid #252525;
                  background: #FFF;
                  height: 40px;
                  width: 100%;
                  padding-left: 10px;
                  p{
                     color: #202020;
                     font-family: 'Rubik';
                     font-size: 14px;
                     font-style: normal;
                     font-weight: 400;
                     line-height: 150%;   
                     margin: 0;       
                  } 
                  input{
                   background: none; 
                   height: 100%; 
                   border: none;
                   outline: none;
                   
                  }        
                }
                }
                 button{
                  border-radius: 8px;
                  border: 1px solid #252525;
                  background: #6C7279;
                  width: 150px;
                  height: 40px; 
                  color: #FFF;
                  font-family: 'Rubik';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 150%; 
                  cursor: pointer;
                  &:active{
                     transform: scale(.98);
                  }
                 }
        }
}
.succes_block{
   margin-top: 20px;
   p{
       color: #2a6e03;
       font-family: 'Rubik';
       font-size: 20px;
       font-style: normal;
       font-weight: 700;
       line-height: 121%;  
       text-align: center;
   }
}