.vacanciesItemCardItem_block{
    .vacanciesItemCardItem_item{

        p{
            color: #202020;
            font-family: 'Rubik';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;  
            margin-top: 10px; 
            max-width: 120px;     
        }
        }
            h6{
                color: rgba(32, 32, 32, 0.56);
                font-family: 'Rubik';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; 
                max-width: 150px;
                margin-top: 5px;
            }
    @media screen and (max-width: 768px) {
        .vacanciesItemCardItem_item{ 
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 20px;
            p{
                max-width: none;
            }
        }
        h6{
            max-width: none;
        }  
    }
}