.vacanciesBotomSliderCard_content {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    flex-shrink: 0;
    width: 100%;
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    .vacanciesBotomSliderCard_block {
      border-radius: 16px;
      background: #f1f1f4;
      padding: 20px;
      max-width: 900px;
      p {
        color: rgba(32, 32, 32, 0.87);
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      .vacanciesBotomSliderCard_item {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: none;
        }
        .vacanciesBotomSliderCardText_block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          width: 100%;
          h3 {
            color: #202020;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }
          
        }
      }
    }
    @media screen and (max-width: 768px) {
      img {
        display: none;
      }
      .vacanciesBotomSliderCard_block {
        .vacanciesBotomSliderCard_item {
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: block;
          }
          .vacanciesBotomSliderCardText_block {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  