.tarifsCards_block{
  margin-top: 30px;
  .form_container{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    z-index: 10000000;
    .form_block{
        max-width: 1200px;
    }
}
}
