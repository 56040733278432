.newsSubscibe_block{
    border-radius: 8px;
    border: 1px solid rgba(32, 32, 32, 0.15);
    background: #FFF; 
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);   
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    padding: 10px;
    input{
      width: 100%;
      height: 100%;
      background: none;
      outline: none;
      border: none;
      color: #202020; 
      font-family: 'Rubik';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      
    }

}