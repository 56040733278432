.businessDiscount_container{
    padding: 50px 20px;
    .businessDiscount_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        max-width: 1200px;
        margin: 0 auto;
        border-radius: 24px;
        background: var(--moskva-mts-ru-nero, #FFF);
        padding: 20px;
        .businessDiscount_item{
            max-width: 400px;
            h3{
                color: var(--moskva-mts-ru-shark, #1D2023);
                font-family: 'Rubik';
                font-size: 20.18px;
                font-style: normal;
                font-weight: 700;
                line-height: 128%;  
            }
            p{
                color: var(--moskva-mts-ru-shark, #1D2023);
                font-family: 'Rubik';
                font-size: 13.945px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;  
                margin-top: 10px;
            }
        } 
        .businessDiscount_collection{
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            gap: 20px; 
            width: 60%;
        }
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            .businessDiscount_collection{
                width: 100%;
                flex-wrap: wrap;
                
            }
        }
    }
}