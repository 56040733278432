.adminservices_container{
    padding: 20px;
 
    .adminservices_block{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        
        .adminservices_item{   

            .adminservicesCards_block{  
                margin:  20px; 
                h1{
                    color: #202020;
                    font-family: 'Rubik';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;                   
                }
     
                .adminservicesCards_item{ 
                    margin-top: 20px;
                }
            }
        }
        @media screen and (max-width: 1000px) {
            display: block;
        }
    }
    .adminServicesModuleModal_container{
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        .adminServicesModuleModal_block{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            background-color: white;
            border-radius: 16px;
        }
    }
    @media screen and (max-width: 768px) {
      padding: 0;  
      padding-top: 40px;

     
        h1{ 
            font-size: 30px;                   
        }
     
  
    }
}