.newsItemBanner_block{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    .newsItemBannerSocial_block{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .newsItemBannerSocial_icon{
            font-size: 25px;
            color: #E1E2E5;
            margin-top: 10px;
            cursor: pointer;
            &:hover{
                color: grey;
            }
            &:nth-child(1){
                margin-top: 0px;
            }
            &:nth-child(2){
               font-size: 23px;
            }
            &:nth-child(3){
               font-size: 28px;
            }
        }

    }
    img{
        width: 90%;
        border-radius: 16px; 
        max-height: 500px;       
    }
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        .newsItemBannerSocial_block{ 
            display: flex;
            align-items: center;
            gap: 10px;
            flex-direction: row;
            .newsItemBannerSocial_icon{
                margin-top: 0px;
                &:nth-child(1){
                    margin-top: 0px;
                }
                &:nth-child(2){
                   font-size: 23px;
                }
                &:nth-child(3){
                   font-size: 26px;
                }
                &:nth-child(5){
                   font-size: 20px;
                }
            }
        }
        img{
            width: 100%; 
        }
    }
}