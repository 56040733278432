.footerLink_container{
  border-top: 1px solid #D1D8D6;  
  padding: 15px 20px;
  .footerLink_block{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;
    a{
        color: #8F9399;
        font-family: 'Rubik';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        text-decoration: none;

    }
  }
}