.newsItemTitle_block {
    h1 {
      color: #202020;
      font-family: "Rubik";
      font-size: 46px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
    p {
      color: #666;
      font-family: Arial;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-top: 10px;
    }
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 36px;
      }
    }
  }